<script>
    // -- IMPORTS

    import { getLocalizedTextBySlug, getLocalizedText, getJsonText } from 'senselogic-gist';
    import { fetchData } from '$lib/base';
    import { languageTagStore } from '$store/languageTagStore';
    import { toast } from '$lib/toast';
    import { fade } from 'svelte/transition';
    import { linear } from 'svelte/easing';
    import { newsletterSubscriptionStore } from '$src/lib/store/newsletterSubscriptionStore';
    import { link } from 'svelte-routing';

    // -- CONSTANTS

    const maxWidthInEmMediaScreen = window.matchMedia( '(min-width: 64em)' );

    // -- VARIABLES

    let email;
    let isEmailValid = false;
    let isMobileScreen = maxWidthInEmMediaScreen.matches;
    let showNewsletterForm = true;

    // -- FUNCTIONS

    function validateEmail(
        email
        )
    {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        return emailRegex.test( email );
    }

    // ~~

    async function subscribeToNewsletter(
        email,
        event = undefined
        )
    {
        event.preventDefault();

        try
        {
            let response = await fetchData(
                '/api/newsletter-subscription',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: getJsonText(
                        {
                            newsletterSubscription:
                                {
                                    isActive: true,
                                    email: email,
                                    languageCode: $languageTagStore
                                }
                        }
                        )
                }
                );

            toast.success( 'Subscribed to newsletter!' );
            showNewsletterForm = false;

            newsletterSubscriptionStore.set( response.newsletterSubscription );

            email = '';
            isEmailValid = false;
        }
        catch ( error )
        {
            console.error( 'Error :', error );
        }
    }

    // --

    function handleResizeEvent(
        )
    {
        isMobileScreen = maxWidthInEmMediaScreen.matches;
    }

    // -- STATEMENTS

    $: isEmailValid = validateEmail( email );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .home-main-newsletter
    {
        margin: 2rem 0;
        width: 90%;
        border-radius: 1rem;
        padding: 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        justify-content: center;
        align-items: center;

        background-color: greenColor950;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);

        +media( desktop )
        {
            margin: 5rem 0;
            width: 64%;
            padding: 3rem;

            gap: 2.5rem;
        }
    }

    .home-main-newsletter h2
    {
        line-height: 2.5rem;
        font-size: 2rem;
        font-weight: 600;
        color: blueColor100;

        +media( desktop )
        {
            line-height: 3.5rem;
            font-size: 3rem;
            font-weight: 700;
            letter-spacing: -0.48px;
        }
    }

    .home-main-newsletter p
    {
        font-size: 0.875rem;
        font-weight: 500;
        text-align: center;
        color: grayColor300;

        +media( desktop )
        {
            font-size: 1rem;
        }
    }

    .home-main-newsletter-form
    {
        width: 85%;

        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        +media( desktop )
        {
            width: 40%;

            flex-direction: row;
        }

        button
        {
            border: none;
        }
    }

    .home-main-newsletter-email:focus,
    .home-main-newsletter-email:active
    {
        border: 1px solid greenColor900;

        +media( desktop )
        {
            border-width: 2px;
        }
    }

    .home-main-newsletter-email
    {
        outline: none;
        min-height: 3.25rem;
        width: 100%;
        min-width: 16rem;
        border: 1px solid grayColor800;
        border-radius: 0.75rem;
        padding: 0.75rem 1rem;

        display: flex;
        gap: 0.5rem;
        align-items: center;

        background: grayColor950;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);

        line-height: 1.125rem;
        font-size: 0.75rem;
        font-weight: 500;
        color: grayColor300;

        transition: border 0 400ms cubic-bezier( 0.76, 0, 0.27, 1 );

        +media( desktop )
        {
            min-width: 20.5rem;
            border-width: 2px;
        }
    }

    .home-newsletter-title-and-description
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        align-self: stretch;

        +media( desktop )
        {
            gap: 1.5rem;
        }
    }

    .home-main-newsletter-button
    {
        height: 3.25rem;
        width: 100%;
        min-width: 16rem;
        border-radius: 0.75rem;
        padding: 0.75rem 2.5rem;

        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        background: blueColor100;

        color: whiteColor;

        cursor: pointer;
        transition: background 0 400ms cubic-bezier(  0.76, 0, 0.27, 1 );

        +media( desktop )
        {
            width: auto;
            min-width: auto;
        }
    }

    .home-main-newsletter-button:hover
    {
        background: blueColor500;
    }
</style>

<svelte:window on:resize={ handleResizeEvent }/>

{#if showNewsletterForm && ( $newsletterSubscriptionStore === null || !$newsletterSubscriptionStore.isActive ) }
    <section
        class="home-main-newsletter"
        out:fade={ { duration: 350, easing: linear } }
        in:fade={ { delay: 350, duration: 350, easing: linear } }
    >
        <div class="home-newsletter-title-and-description">
            <h2>
                { getLocalizedTextBySlug( 'home-newsletter-title', $languageTagStore ) }
            </h2>
            <p>
                { getLocalizedTextBySlug( 'home-newsletter-description', $languageTagStore ) }
            </p>
        </div>
        <form class="home-main-newsletter-form" on:submit={ ( event ) =>  subscribeToNewsletter( email, event ) }>
            <input
                class="home-main-newsletter-email"
                type="email"
                required
                placeholder={ getLocalizedTextBySlug( 'home-newsletter-email-placeholder' ) }
                bind:value={ email }
            />
            <button
                type="submit"
                class="home-main-newsletter-button"
            >
                {#if isMobileScreen}
                    <span class="white-right-arrow-icon size-150"/>
                {:else}
                    { getLocalizedText( `E-mail address¨fr:Adrese é-mail¨de:E-Mail Adresse` ) }
                    <span class="white-right-caret-icon size-150"/>
                {/if}
            </button>
        </form>
    </section>
{:else}
    <section
        class="home-main-newsletter"
        out:fade={ { duration: 350, easing: linear } }
        in:fade={ { delay: 350, duration: 350, easing: linear } }
    >
        <div class="home-newsletter-title-and-description">
            <h2 class="text-align-center">
                { getLocalizedTextBySlug( 'home-page-head-title', $languageTagStore ) }
            </h2>
            <p>
                { getLocalizedTextBySlug( 'blog-discover-description-label', $languageTagStore ) }
            </p>
        </div>
        <a
            class="home-main-newsletter-button"
            href="/{ $languageTagStore }/blog"
            use:link
        >
            { getLocalizedTextBySlug( 'blog-discover-moien-label', $languageTagStore ) }
        </a>
    </section>
{/if}
